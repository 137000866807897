<template>
    <div id="python_snake">
        <div id="python-line">
            <TickerName class="smal-line-animate" :profession="profession" :marquereverse="false" :marqueduration="30"/>
            <TickerName class="big-line-animate" :profession="profession" :marquereverse="true" :marqueduration="150"/>
        </div>
        <div id="two_snake">
            <PythonTong  class="abs_right"/>
            <PythonTong class="abs_left"/>
        </div>
    </div>
</template>
<script>
    import PythonTong from "@/components/PythonTong.vue"
    import TickerName from "@/components/TickerName";

    export default {
        name: 'python_snake',
        props:['profession'],
        components: {
            PythonTong,
            TickerName,
        },
        mounted(){
            document.addEventListener("DOMContentLoaded", function() {
                let asd = document.getElementsByClassName('big-line-animate')[0].offsetTop
                document.getElementById('my_photo').style.height = asd
                console.log('asd', asd)
            })
        },
    }

</script>
<style>
    #python_snake{
        overflow: hidden;
        width: 100vw;
    }
    #python-line {
        position: absolute;
        z-index: 20;
        width: 100%;
        opacity: 0;
        animation: fadeIn 1s 5s forwards;
    }
    .smal-line-animate {
        font-size: 6vw;
        font-weight: 100;
        text-transform: uppercase;
        width: 100vw;
        overflow: hidden;
    }

    .big-line-animate {
        text-transform: uppercase;
        font-size: 6vw;
        font-weight: 700;
        margin: 0px 0 -10px auto;
        width: 100vw;
        overflow: hidden;
    }

    #two_snake {
        display: flex;
        justify-content: space-between;
        position: relative;
        margin-top: 9vw;
    }

    .abs_right {
        right: 0;
        transform: scaleX(-100%);
        z-index: 10;

    }

    .abs_left {
        left: 0;
        z-index: 10;
    }
    @media screen and (min-width:1024px) {
        #python-line{
            margin-top: 12vh;
        }
    }
    @media screen and (max-width:1024px) {

        .big-line-animate, .smal-line-animate {
            font-size: 45px;
        }

        .big-line-animate, .smal-line-animate  {
            margin: -20px 0 -10px auto;

        }

        #two_snake .abs_right {
            transform: scaleX(100%);
            width: 60%;
            margin-left: auto;
            margin-top: -35vw;

        }

        .abs_left {
            display: none;
        }

    }
</style>