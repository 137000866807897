<template>
    <marqueetext :reverse="marquereverse" :duration="marqueduration" > <span v-for="index in 5" :key="index">&nbsp;{{profession}}&nbsp;</span> </marqueetext>
</template>

<script>
    import MarqueeText from 'vue-marquee-text-component'
    export default {
        name: "TickerName",
        components:{
            marqueetext: MarqueeText,
        },
        props:['profession', 'marquereverse', 'marqueduration',]
    }
</script>

<style scoped>

</style>