<template>
    <div class="block" id="soft_skills">
        <ArticleTitle :rotateds="true" :shovsnakes="true" :articleid="'soft_skills_n'" :articletitle="'Soft Skill'" id="soft_skills_n"/>
        <div class="colomn" id="soft_skills_content">
            <div class="wraper softsk" v-for="sskill  in raw.softskills_set" :key="sskill.name">
                <div class="title">
                    <div class="name">{{sskill.name}}</div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    // import TrilineBlock from "@/components/TrilineBlock";
    import ArticleTitle from "@/components/ArticleTitle";
    // import $ from "jQuery";
    export default {
        name: "SoftSkill",
        components:{
            ArticleTitle,
            // triline:TrilineBlock,
        },
        mounted() {
            this.$store.dispatch('GetData')
            // $('.softsk').each(()=>{
            //     $(this).mouseover(()=>{
            //         console.log('mouse over')
            //     })
            // });
        },
        computed: {
            ...mapState(["raw",]),
        },
    }
</script>

<style scoped>
    #soft_skills .wraper{
        width: 20vw;
        min-height: 10vw;
        margin: -2px -2px;
        padding: 5px 5px;
        display: flex;
        /*justify-content: center;*/
        /*align-items: center;*/

    }
    #soft_skills .title{
        align-items: center;
        justify-content: center;
    }
    #soft_skills .name{
        font-size: 2vw;
    }
    @media screen and (min-width:1024px) {
        .descr{
            display: none;
        }

        #soft_skills .wraper:nth-child(1) {
            /*border-top: 3px solid #555555;*/
            border-left: 3px solid #555555;
        }

        #soft_skills .wraper:nth-child(3), #soft_skills .wraper:nth-child(8) {
            border-bottom: 3px solid #555555;
        }

        #soft_skills .wraper:nth-child(4), #soft_skills .wraper:nth-child(5) {
            border-right: 3px solid #555555;
        }

        #soft_skills .wraper:nth-child(2) {
            border-top: 3px solid #555555;
        }
    }
    @media screen and (max-width:1024px) {
        #soft_skills .name{
            font-size: 6vw;
        }
        #soft_skills .wraper{
            width: 100%;
            /*border:none;*/
        }
        /*#soft_skills .wraper:nth-child(2n+2){*/
        /*    border-top: 1px solid #333;*/
        /*    !*border-top: 3px solid #555555;*!*/
        /*}*/
        /*#soft_skills .wraper:nth-child(3n){*/
        /*    border-left: 1px solid #555555;*/
        /*    border-right: 1px solid #555555;*/
        /*}*/
    }

</style>
