<template>
    <div class="block" id="projects">
        <ArticleTitle :rotateds="true" :shovsnakes="true" :articleid="'projects_n'" :articletitle="'projects'" id="projects_n"/>
        <div class="colomn" id="projects_content">
            <div class="wraper" v-for="pdata in raw.projects_set" :key="pdata.name">
                <ContentBlock :datac="pdata" :hardskill="raw.hardskillcategory_set"/>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import ArticleTitle from "@/components/ArticleTitle";
    import ContentBlock from "@/components/ContentBlock";

    export default {
        name: "CreatedProjects",
        mounted() {
            this.$store.dispatch('GetData')
        },
        components: {
            ArticleTitle,
            ContentBlock,
        },
        computed: mapState(["raw",]),
        methods: {
            title() {
                return this.raw.navigations_set.find(x => x['variant'] === "projects_n").text
            },
        }
    }
</script>

<style scoped>

</style>