<template>
    <div>
        <div class="footer">
            <div class="footer_adres">
                <div class="footer_block">
                    <div class="title">Adress:</div>
                    <div>{{raw.country}}, {{raw.city}},</div>
                    <div>{{raw.adress}}</div>
                </div>
            </div>
            <div class="footer_contacts">
                <div class="footer_block">
                    <div class="title">Contacts:</div>
                    <div><a :href="'tel:+38'+raw.telephone"> +38{{raw.telephone}}</a></div>
                    <div><sup>Phone/Telegram/WhatsApp</sup></div>
                </div>
                <div class="footer_block">
                    <div class="title hide">&nbsp;</div>
                    <div><a :href="raw.linkedin">linkedIn</a></div>
                    <div><a :href="raw.github">GitHub</a></div>
                </div>
            </div>
            <div>
                <div class="footer_block">
                    <a href="http://www.fotka.kiev.ua/cv/print/" target="_blank" title="Print CV">Print CV</a>
                </div>
                <div class="footer_block">
                    <a href="http://www.fotka.kiev.ua/cv/pdf/" title="Download PDF CV">PDF CV</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    export default {
        name: "MainFooterBlock",
        mounted() {
        },
        computed: {
            ...mapState(["raw",]),
        },
    }
</script>

<style scoped>
    .footer{
        margin-top: 100px;
        border-top: 1px solid #666;
        width: 100vw;
        background-color: #084C66;
        padding: 50px 120px;
        display: flex;
        flex-direction: row;
        font-weight: 200;
        letter-spacing: 1px;
        flex-wrap: wrap;
    }
    .footer_adres, .footer_contacts{
        display: flex;
        /*flex-direction: column;*/
        padding: 2px 10px;
        border-left: 2px solid;
        border-color: rgba(255,255,255,0.5);
        margin: 0 50px;
    }
    .footer_block{
        padding-left: 10px;
        margin-right: 30px;
    }
    a{
        color: #fff;
        font-size: medium;
    }
    .title{
        text-transform: uppercase;
        font-weight: 400;
        padding-bottom: 10px;
    }
    @media screen and (max-width:1024px) {
        .footer{
            padding: 20px 10px;
        }
        .footer_block{
            display: flex;
            flex-direction: column;
            justify-content: space-evenly
        }
        .footer_adres, .footer_contacts{
            padding: 2px 10px;
            border-left: 2px solid;
            border-color: rgba(255,255,255,0.5);
            margin: 20px 10px;
        }
        .hide{
            display: none;
        }
    }
</style>