import { createApp } from 'vue'
import { createStore } from 'vuex'
import axios from "axios";
import AppMain from './AppMain.vue'


// Create a new store instance.
const store = createStore({
  state: {
        raw: [],
        info: [],
        navLinks: [],
        socicons: [],
        spinner: true,
    },
    actions: {
        GetData: ({ commit }) => {
            axios.get('https://fotka.kiev.ua/cv/api/alldata/').then(response => {commit('GetInfo', response.data)})
        },
    },
    mutations: {
        GetInfo: (state, infos) => {
            state.raw = infos[0]
            state.navLinks =infos[0].navigations_set.filter(x => x.variant !=="icons")
            state.socicons = infos[0].navigations_set.filter(x => x.variant ==="icons")
            state.spinner = false
        }
    },
})

const app = createApp(AppMain)
app.use(store)
app.mount('#app');