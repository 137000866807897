<template>
    <div class="conteiner">
        <mainspiner />
        <topmenu />
        <developer :profession="raw.profession" :aboutme="raw.introduction"/>
        <toppythons :profession="raw.profession"/>
        <hardskill />
        <projects />
        <experience />
        <softskill />
        <footerblock />

    </div>
</template>

<script>
    import TopMenu from "@/components/TopMenu.vue";
    import DeveLoperTop from "@/components/DeveLoperTop.vue";
    import TopPythons from "@/components/TopPythons.vue"
    import HardSkill from "@/components/HardSkill";
    import ExperiencePreviusWorks from "@/components/ExperiencePreviusWorks";
    import CreatedProjects from "@/components/CreatedProjects";
    import SoftSkill from "@/components/SoftSkill";
    import MainFooterBlock from "@/components/MainFooterBlock";
    import MainSpiner from "@/components/MainSpiner";
    import {mapState} from "vuex";

    export default {
        name: 'AppMain',
        components: {
            mainspiner: MainSpiner,
            topmenu: TopMenu,
            developer: DeveLoperTop,
            toppythons: TopPythons,
            hardskill: HardSkill,
            experience: ExperiencePreviusWorks,
            projects: CreatedProjects,
            softskill: SoftSkill,
            footerblock: MainFooterBlock,
        },
        computed: mapState(["raw", "navLinks"]),

        mounted() {
            this.$store.dispatch('GetData')
        },

    }
</script>
<style>
    html {
        scroll-behavior: auto;
    }
    body {
        overflow-x: hidden;
        margin: 0;
        padding: 0;
        background-color: rgb(0, 0, 0);
        color: white;
        font-family: 'Oswald';
    }

    h1 {
        text-transform: uppercase;
        font-size: 7vw;
        font-family: 'Oswald';
        font-style: normal;
        font-weight: 400;
        line-height: 8vw;
        margin: 0 0 100px 0;
        width: 65%;
        min-height: 16vw;
    }

    h2 {
        font-size: 4vw;
        width: fit-content;
        text-transform: uppercase;
        margin: 0;
    }

    a {
        color: #084C66;
        font-size: 24px;

    }

    a:hover {
        text-decoration-color: #fff;
    }

    .conteiner {
        width: 100%;
        overflow: hidden;
    }

    .colomn {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 2vw 0 0 120px;
    }

    .wraper {
        margin: 30px 0;
        min-width: 280px;
        width: calc(100% - 120px);
        margin-right: 3vw;
        margin-bottom: 10vw;
    }

    .rotate {
        transform: rotate(-90deg);
        margin-left: 113px;
    }

    .header_container {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .header_left {
        width: 60%;
        padding-top: 14vw;

    }
    @media screen and (max-width:1024px) {
        h1{
            width: 100%;
            font-size: 45px;
            line-height: 10vw;
            margin: 0 0 40vh 0;
        }
        .wraper{
            width: 100%;
        }
        .element{
            background-size: 0.2%;
        }
        .colomn{
            margin: 0 10px 50px 10px;
        }
        #python-line{
            animation: fadeIn 1s 3s forwards;
        }
        svg{
            width:100%;
            height: 90vw;
        }

        a {

            font-size: 15px;
        }


    }

    @keyframes slide-allwais {
        0% {
            margin-left: -50px;
        }
        3% {
            margin-left: -50px;
        }
        45% {
            margin-left: 98%;
        }
        55% {
            margin-left: 98%;
        }
        97% {
            margin-left: -50px;
        }
        100% {
            margin-left: -50px;
        }
    }

    @keyframes blink {
        0% {
            fill: none;
        }
        100% {
            fill: #ce1b28;
        }
    }

    @keyframes run-top {
        0% {
            margin-top: 0vw;
        }
        100% {
            margin-top: -3vw;
        }
    }

    @keyframes run-down {
        0% {
            margin-top: 0vw;
        }
        100% {
            margin-top: 3vw;
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes move-in-from-right {
        0% {
            right: -100%;
        }
        90% {
            right: 2%;
        }
        100% {
            right: 0%;
        }
    }

    @keyframes move-in-from-top {
        0% {
            margin-bottom: 100%;
            margin-top: 0;
        }
        100% {
            margin-bottom: 0;
            margin-top: 100%;
        }
    }


</style>
